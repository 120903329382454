import Vue from 'vue'
import App from './App.vue'
import router from './router';
import './index.css'
import Toasted from 'vue-toasted';
import VueTippy, { TippyComponent } from "vue-tippy";
import store from './store';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTwitter, faDiscord } from '@fortawesome/free-brands-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

Vue.use(Toasted);

const options = {
  theme: "bubble",
  position: "top-right",
  duration: 3000,
  keepOnHover: true,
  className: ['bg-white', 'font-normal', 'text-sm', 'text-justify', 'py-4', 'px-5', 'shadow-lg', 'rounded', 'tracking-widened']
}
Vue.toasted.register('success', (payload) =>  !payload.message ? "Request was processed." : payload.message, {
  ...options,
  className: [...options.className, 'text-green-500']
});
Vue.toasted.register('error', (payload) =>  !payload.message ? "Oops.. Something Went Wrong.." : payload.message, {
  ...options,
  className: [...options.className, 'text-red-500']
});

Vue.use(VueTippy, {
  theme: 'translucent',
  flipDuration: 0,
  arrow: true,
  animation: 'shift-away'
});
Vue.component("tippy", TippyComponent);

library.add(faCheck);
library.add(faTwitter);
library.add(faDiscord);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
