import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from './views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/myth',
    name: 'myth',
    // route level code-splitting
    // this generates a separate chunk (myth.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "myth" */ './views/Myth.vue')
  },
  {
    path: '/lair',
    name: 'lair',
    // route level code-splitting
    // this generates a separate chunk (lair.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "lair" */ './views/Lair.vue')
  },
  {
    path: '/attributes',
    name: 'attributes',
    // route level code-splitting
    // this generates a separate chunk (attributes.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "attributes" */ './views/Attributes.vue')
  },
  // {
  //   path: '/roadmap',
  //   name: 'roadmap',
  //   // route level code-splitting
  //   // this generates a separate chunk (roadmap.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "roadmap" */ './views/Roadmap.vue')
  // },
];

const router = new VueRouter({
  routes
});

export default router;
