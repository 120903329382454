<template>
  <div>
    <div class="max-w-sm mx-auto mt-20 pt-10">
      <img class="mx-auto w-80" src="../assets/medusa.gif"/>
    </div>
    <div class="text-center">
      <div class="mt-5">
        <div v-if="showCountdown">
          <p class="text-lg font-normal text-gray-500 tracking-enormous uppercase">
            {{ countDown }}
          </p>
        </div>
        <div v-else>
          <action-button
            class="py-3"
            :disabled="mint_loading"
            @click="decreaseMintIntent"
          >-</action-button>
          <action-button
            class="py-3 mx-3"
            :loading="mint_loading"
            :disabled="mintingDisabled"
            :transparent="false"
            @click="mint"
          >Mint {{ mint_intent }}</action-button>
          <action-button
            class="py-3"
            :disabled="mint_loading"
            @click="increaseMintIntent"
          >+</action-button>
          <div class="mt-10">
            <p class="text-medium font-normal tracking-wide text-gray-500 tracking-enormous">GORGONS: {{ minted_total }} / {{ max_supply }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActionButton from '../components/ActionButton.vue';
import Countdown from '../mixins/Countdown.vue';
import {mapState, mapGetters, mapActions, mapMutations} from 'vuex';

export default {
  name: 'App',
  components: {
    ActionButton
  },
  computed: {
    ...mapState(['account', 'mint_loading', 'open', 'minted_total', 'mint_intent', 'max_supply']),
    ...mapGetters(['truncatedAddress']),
    mintingDisabled() {
      return !this.account || this.mint_loading || !this.open;
    },
  },
  methods: {
    ...mapMutations(['increaseMintIntent', 'decreaseMintIntent']),
    ...mapActions(['mintItem', 'getTotalMinted']),
    async mint() {
      try {
        await this.mintItem(this.mint_intent);
        this.$toasted.global.success({message: `${this.mint_intent} Gorgon${this.mint_intent > 1 ? 's were' : ' was'} successfully minted.`});
        await this.getTotalMinted();
      } catch (error) {
        console.log(error.message)
      }
    }
  },
  mixins: [Countdown]
}
</script>
