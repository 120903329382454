var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('button',{staticClass:"px-6 py-2 border border-main border-4 rounded-lg transition duration-200 font-medium tracking-wide",class:{
        'text-main bg-transparent hover:bg-main hover:text-white': _vm.transparent,
        'text-white bg-main hover:bg-transparent hover:text-main': !_vm.transparent,
        'pointer-events-none opacity-40': _vm.disabled
    },attrs:{"disabled":_vm.disabled},on:{"click":_vm.click}},[_vm._t("default")],2):_c('button',{staticClass:"px-6 py-2 border border-main border-4 rounded-lg transition duration-200 font-medium tracking-wide opacity-40",class:{
        'text-main bg-transparent': _vm.transparent,
        'text-white bg-main': !_vm.transparent
    }},[_vm._v("Loading...")])}
var staticRenderFns = []

export { render, staticRenderFns }