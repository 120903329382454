import Vue from 'vue';
import Vuex from 'vuex';
import GorgonMedusas from '../contracts/GorgonMedusas.json';
import Web3EthContract from 'web3-eth-contract';
import Web3 from 'web3';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        account_loading: false,
        mint_loading: false,
        collection_loading: false,
        lair_loading: false,
        owner_wallet: '',
        wallet_items: [],
        account: null,
        contract: null,
        web3: null,
        minted_total: 0,
        mint_intent: 1,
        mint_max: 2,
        max_supply: 1040,
        open: false,
        fee: 0,
        wei_divider: 1000000000000000000,
        error: ''
    },
    getters: {
        truncatedAddress({ account }) {
            if (account) {
                return `${account.slice(0, 5)}...${account.slice(-4)}`;
            }
            return '';
        }
    },
    mutations: {
        toggleLoading(state, {type, value}) {
            state[`${type}_loading`] = value;
        },
        updateError(state, payload) {
            state.error = payload;
        },
        updateConnection(state, { account, contract, web3 }) {
            state.account = account;
            state.contract = contract;
            state.web3 = web3;
        },
        updateAccount(state, payload) {
            state.account = payload;
        },
        updateOpen(state, payload) {
            state.open = payload;
        },
        updateMaxSupply(state, payload) {
            state.max_supply = payload;
        },
        updateMintedTotal(state, payload) {
            state.minted_total = payload;
        },
        updateMintMax(state, payload) {
            state.mint_max = payload;
        },
        updateFee(state, payload) {
            state.fee = payload / state.wei_divider;
        },
        increaseMintIntent(state) {
            const value = state.mint_intent < state.mint_max ? 1 : 0;
            state.mint_intent += value;
        },
        decreaseMintIntent(state) {
            const value = state.mint_intent > 1 ? 1 : 0;
            state.mint_intent -= value;
        },
        updateOwnerWallet(state, payload) {
            state.owner_wallet = payload;
        },
        updateWalletItems(state, payload) {
            state.wallet_items = [...payload].sort((a, b) => {
                return a - b;
            });
        }
    },
    actions: {
        async connectWallet({ commit }) {
            commit('toggleLoading', {type: 'account', value: true});

            // check ethereum property exists
            const { ethereum } = window;
            // verify metamask is installed
            const metamask = ethereum && ethereum.isMetaMask;
            if (metamask) {
                // set provider for further contract instantiations
                Web3EthContract.setProvider(ethereum);
                // instantiate web3 object
                const web3 = new Web3(ethereum);
                try {
                    // get accounts
                    const accounts = await ethereum.request({
                        method: "eth_requestAccounts",
                    });
                    // get network
                    const networkId = await ethereum.request({
                        method: "net_version",
                    });

                    // get network details
                    const contractNetworkData = process.env.VUE_APP_NETWORK_ID ?
                        networkId === process.env.VUE_APP_NETWORK_ID :
                        await GorgonMedusas.networks[networkId];
   
                    if (contractNetworkData) {
                        // setup contract
                        const contract = new Web3EthContract(
                            GorgonMedusas.abi,
                            process.env.VUE_APP_CONTRACT_ADDRESS
                        );

                        // update connection details
                        commit('updateConnection', {
                            account: accounts[0],
                            contract,
                            web3
                        });

                        // subscribe events
                        contract.events.TotalSupplyUpdated().on('data', (event) => {
                            commit('updateMintedTotal', event.returnValues.supply);
                        });
                        contract.events.MintStateUpdated().on('data', (event) => {
                            commit('updateOpen', event.returnValues.state);
                        });
                        contract.events.MintFeeUpdated().on('data', (event) => {
                            commit('updateFee', event.returnValues.mintFee);
                        });
                        contract.events.MintMaxAmountUpdated().on('data', (event) => {
                            commit('updateMintMax', event.returnValues.amount);
                        });

                        // register listeners
                        window.ethereum.on("accountsChanged", (accounts) => {
                            commit('updateAccount', accounts[0]);
                        });
                        window.ethereum.on("chainChanged", () => {
                            window.location.reload();
                        });

                        commit('toggleLoading', {type: 'account', value: false});
                    } else {
                        commit('toggleLoading', {type: 'account', value: false});
                        throw new Error('Fantom network must be selected.');
                    }
                } catch (error) {
                    commit('toggleLoading', {type: 'account', value: false});
                    throw new Error(error.message);
                }
            } else {
                commit('toggleLoading', {type: 'account', value: false});
                throw new Error('Metamask not installed.');
            }
        },
        async mintItem({ commit, state }, payload) {
            commit('toggleLoading', {type: 'mint', value: true});

            try {
                const receipt = await state.contract.methods.mintGorgons(payload).send({
                    from: state.account,
                    value: state.web3.utils.toWei((payload * state.fee).toString(), 'ether')
                });
                return receipt;
            } catch (error) {
                throw new Error(error.message);
            } finally {
                commit('toggleLoading', {type: 'mint', value: false});
            }
        },
        async getStatus({ commit, state }) {
            try {
                const open = await state.contract.methods.isOpen().call();
                commit('updateOpen', open);
            } catch (error) {
                console.log(error);
            }
        },
        async getMaxSupply({ commit, state }) {
            try {
                const max = await state.contract.methods.getMaxSupply().call();
                commit('updateMaxSupply', max);
            } catch (error) {
                console.log(error);
            }
        },
        async getTotalMinted({ commit, state }) {
            try {
                const total = await state.contract.methods.totalSupply().call();
                commit('updateMintedTotal', total);
            } catch (error) {
                console.log(error);
            }
        },
        async getMintFee({ commit, state }) {
            try {
                const fee = await state.contract.methods.getFee().call();
                commit('updateFee', fee);
            } catch (error) {
                console.log(error);
            }
        },
        async getMintMax({ commit, state }) {
            try {
                const max = await state.contract.methods.getMaxPerMint().call();
                commit('updateMintMax', max);
            } catch (error) {
                console.log(error);
            }
        },
        async searchOwner({ commit, state }, payload) {
            commit('toggleLoading', {type: 'lair', value: true});
            try {
                const tokens = await state.contract.methods.getWalletGorgons(payload).call();
                commit('updateWalletItems', tokens);
                console.log(payload, tokens);
            } catch (error) {
                throw new Error(error);
            } finally {
                commit('toggleLoading', {type: 'lair', value: false});
            }
        }
    }
});