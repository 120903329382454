<template>
  <div id="app" class="bg-gray-200 h-screen font-body">
    <div class="container mx-auto py-6">
      <div class="flex items-center">
        <h1 class="text-2xl font-medium tracking-widest">
          <action-link @click="$router.push({name: 'home'}).catch(err => {})">MEΔUΣA</action-link>
        </h1>
        <div class="flex-1 text-right">
          <action-link class="mr-7" @click="$router.push({name: 'myth'}).catch(err => {})">Myth</action-link>
          <action-link class="mr-7" @click="$router.push({name: 'lair'}).catch(err => {})">Lair</action-link>
          <action-link class="mr-7" @click="$router.push({name: 'attributes'}).catch(err => {})">Attributes</action-link>
          <action-link v-if="false" class="mr-7" @click="$router.push({name: 'roadmap'}).catch(err => {})">Roadmap</action-link>
          <action-button
            :loading="account_loading"
            :disabled="!!account && !!contract"
            @click="connect"
          >{{ account && contract ? truncatedAddress : 'Connect Wallet' }}</action-button>
        </div>
      </div>
      <div class="mt-6 mb-20">
        <keep-alive>
          <router-view/>
        </keep-alive>
      </div>
    </div>
    <div class="fixed bottom-0 w-full text-center py-6">
      <a href="https://twitter.com/Gorgon__Medusa" target="_blank" class="inline-block mx-3 cursor-pointer">
        <font-awesome-icon :icon="['fab', 'twitter']" size="2x" class="text-gray-400 hover:text-gray-500 transition duration-200"/>
      </a>
      <a href="https://discord.gg/MvNebVFXaw" target="_blank" class="inline-block mx-3 cursor-pointer">
        <font-awesome-icon :icon="['fab', 'discord']" size="2x" class="text-gray-400 hover:text-gray-500 transition duration-200"/>
      </a>
    </div>
  </div>
</template>

<script>
import ActionButton from './components/ActionButton.vue';
import ActionLink from './components/ActionLink.vue';
import {mapState, mapGetters, mapActions} from 'vuex';

export default {
  components: {
    ActionButton,
    ActionLink
  },
  async created() {
    try {
      await this.connectWallet();
    } catch (error) {
      console.log(error.message);
    }
  },
  computed: {
    ...mapState(['account', 'contract', 'account_loading']),
    ...mapGetters(['truncatedAddress']),
  },
  methods: {
    ...mapActions(['connectWallet', 'getStatus', 'getMaxSupply', 'getTotalMinted', 'getMintFee', 'getMintMax']),
    async connect() {
      try {
        await this.connectWallet();
      } catch (error) {
        this.$toasted.global.error({message: error.message});
        console.log(error.message);
      }
    },
  },
  watch: {
    async contract() {
      await this.getStatus();
      await this.getMaxSupply();
      await this.getTotalMinted();
      await this.getMintFee();
      await this.getMintMax();
    }
  }
}
</script>
