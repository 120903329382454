<template>
    <a @click="$emit('click')" class="cursor-pointer text-main hover:text-gray-500 transition duration-200">
        <slot/>
    </a>
</template>

<script>
export default {
    props: {
        url: {
            type: String,
            default: '#'
        }
    }
}
</script>