<script>
import moment from 'moment-timezone';
import {mapMutations} from 'vuex';

export default {
    created() {
        this.updateCurrent();
    },
    data() {
        return {
            mintTime: moment.tz("2022-01-17T19:30", 'Europe/London'),
            currentTime: moment.tz('Europe/London')
        }
    },
    computed: {
        timeDiff() {
            return this.calculateDiff();
        },
        countDown() {
            const duration = moment.duration(this.mintTime.diff(this.currentTime));
            const days = Math.floor(duration.asDays());
            return `${days < 10 ? '0' + days : days}:${moment(this.timeDiff).utc(0).format('HH:mm:ss')}`;
        },
        showCountdown() {
            return this.timeDiff > 0 && this.timeDiff > 1000;
        }
    },
    methods: {
        ...mapMutations(['updateOpen']),
        updateCurrent() {
            if (this.showCountdown) {
                setTimeout(() => {
                    this.currentTime = moment.tz('Europe/London');
                    this.updateCurrent();
                }, 1000);
            } else {
                this.updateOpen(true);
            }
        },
        calculateDiff() {
            return this.mintTime.diff(this.currentTime);
        }
    },
}
</script>