<template>
    <button
        v-if="!loading"
        class="px-6 py-2 border border-main border-4 rounded-lg transition duration-200 font-medium tracking-wide"
        :class="{
            'text-main bg-transparent hover:bg-main hover:text-white': transparent,
            'text-white bg-main hover:bg-transparent hover:text-main': !transparent,
            'pointer-events-none opacity-40': disabled
        }"
        :disabled="disabled"
        @click="click"
    >
        <slot/>
    </button>
    <button
        v-else
        class="px-6 py-2 border border-main border-4 rounded-lg transition duration-200 font-medium tracking-wide opacity-40"
        :class="{
            'text-main bg-transparent': transparent,
            'text-white bg-main': !transparent
        }"
    >Loading...</button>
</template>

<script>
export default {
    props: {
        transparent: {
            type: Boolean,
            default: true
        },
        loading: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        click() {
            if (!this.disabled) {
                this.$emit('click');
            }
        }
    }
}
</script>